import { convertChordSelectorStateToString } from "../_ChordSelectors/ChordSelector/util";
import { convertChordFunctionSelectorStateToString } from "../_ChordSelectors/ChordFunctionSelector/util";
import type { ChordSearchType } from "./ChordProgressionSearchBar/SearchTypeSelector/util";
import type { OtherPageLink } from "./ChordProgressionSearchBar/SearchTypeSelector";
import { exhaustiveGuard } from "~/libs/exhaustiveGuard";
import type { ToneType } from "~/libs/chordProgressionUtil/ToneType";
import type { SearchHistory } from "~/libs/data/localStorage/searchHistoriesStorage";
import { getTrackListPagePath } from "~/routes/tracks._index/path";
import type { Locales } from "~/libs/i18n/localeTypes";
import { t } from "~/libs/i18n/dictionaries";

export function getToneType(type: ChordSearchType): ToneType | undefined {
  switch (type) {
    case "free_input": {
      return undefined;
    }
    case "chord": {
      return "nashville";
    }
    case "degree": {
      return "number_degree";
    }
    case "function": {
      return "nashville";
    }
    default: {
      exhaustiveGuard(type);
      return undefined;
    }
  }
}

// eslint-disable-next-line max-statements
export function convertSearchHistoryToOtherPageLinks(
  searchHistory: SearchHistory,
  _locale: Locales,
): OtherPageLink {
  const dict = t[_locale];

  // 最下部のswitchのexhaustiveGuardがうまく動かないので、ここでダミーのexhaustiveGuardを入れておく。気づくためにだけ、、
  const { searchType } = searchHistory;

  switch (searchType) {
    case "free_input": {
      const { q } = searchHistory;
      return {
        href: getTrackListPagePath({
          searchCondition: searchHistory,
        }),
        text: `${dict.free_input}: ${q}`,
      };
    }
    case "chord": {
      const { chordSelectorStatePropsList } = searchHistory;
      return {
        href: getTrackListPagePath({
          searchCondition: searchHistory,
        }),
        text: `${dict.chord}: ${chordSelectorStatePropsList
          .map((chordSelectorStateProps) =>
            convertChordSelectorStateToString({
              state: chordSelectorStateProps,
              inputtingPlaceholder: "",
            }),
          )
          .join(" - ")}`,
      };
    }
    case "degree": {
      const { chordSelectorStatePropsList } = searchHistory;

      return {
        href: getTrackListPagePath({
          searchCondition: searchHistory,
        }),
        text: `${dict.degree}: ${chordSelectorStatePropsList
          .map((chordSelectorStateProps) =>
            convertChordSelectorStateToString({
              state: chordSelectorStateProps,
              inputtingPlaceholder: "",
            }),
          )
          .join(" - ")}`,
      };
    }
    case "function": {
      const { chordFunctionSelectorStatePropsList } = searchHistory;
      return {
        href: getTrackListPagePath({
          searchCondition: searchHistory,
        }),
        text: `${dict.function}: ${chordFunctionSelectorStatePropsList
          .map((chordFunctionSelectorStateProps) =>
            convertChordFunctionSelectorStateToString({
              state: chordFunctionSelectorStateProps,
              inputtingPlaceholder: "",
            }),
          )
          .join(" - ")}`,
      };
    }
    case undefined: {
      // TODO: 適当にやったので直して直して。たぶんここには来ない想定なはず
      return {
        href: "/",
        text: "",
      };
    }
    default: {
      exhaustiveGuard(searchType);
      return {
        href: "/error",
        text: "",
      };
    }
  }
}
