import { albumApiClient } from "../api.artists.$artistId.albums/client";
import { contactApiClient } from "../api.contact/client";
import { meThemeApiClient } from "../api.me.theme/client";

export const apiClient = {
  albums: {
    _: albumApiClient,
  },
  me: {
    theme: meThemeApiClient,
  },
  contact: {
    _: contactApiClient,
  },
};
