import { useEffect, useState } from "react";
import type { ChordSearchType } from "../../SiteHeader/ChordProgressionSearchBar/SearchTypeSelector/util";
import { useChordProgressionSearchBarContext } from "../../SiteHeader/useContext";
import type { ChordSelectorStateProps } from "./util";

/**
 * ChordSearchTypeがchordとdegreeの両方を共通で扱うhooks
 *
 * functionの場合は動作は保証しない
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, max-statements
export function useChordSelectorCommonState({
  chordSearchType,
  initialValues,
}: {
  chordSearchType: ChordSearchType;
  initialValues: {
    nashvilleChordSelectorStateList: ChordSelectorStateProps[];
    degreeChordSelectorStateList: ChordSelectorStateProps[];
  };
}) {
  const { setSiteHeaderInitialValues } = useChordProgressionSearchBarContext();

  // -----------------------------------検索タイプがchordの場合のステート

  // コードセレクターの状態リスト
  const [nashvilleChordSelectorStateList, setNashvilleChordSelectorStateList] =
    useState<ChordSelectorStateProps[]>(
      initialValues.nashvilleChordSelectorStateList,
    );

  // 選択しているコードセレクターのインデックス
  const [
    selectedNashvilleChordSelectorStateIndex,
    setSelectedNashvilleChordSelectorStateIndex,
  ] = useState<undefined | number>();

  // コピーしたコードセレクターの状態
  const [
    copiedNashvilleChordSelectorState,
    setCopiedNashvilleChordSelectorState,
  ] = useState<ChordSelectorStateProps | undefined>();

  // -----------------------------------検索タイプがdegreeの場合のステート
  // コードセレクターの状態リスト
  const [degreeChordSelectorStateList, setDegreeChordSelectorStateList] =
    useState<ChordSelectorStateProps[]>(
      initialValues.degreeChordSelectorStateList,
    );

  // 選択しているコードセレクターのインデックス
  const [
    selectedDegreeChordSelectorStateIndex,
    setSelectedDegreeChordSelectorStateIndex,
  ] = useState<undefined | number>();

  // コピーしたコードセレクターの状態
  const [copiedDegreeChordSelectorState, setCopiedDegreeChordSelectorState] =
    useState<ChordSelectorStateProps | undefined>();

  // -----------------------------------まとめたもの

  const chordSelectorStateList =
    chordSearchType === "chord"
      ? nashvilleChordSelectorStateList
      : degreeChordSelectorStateList;

  const selectedChordSelectorStateIndex =
    chordSearchType === "chord"
      ? selectedNashvilleChordSelectorStateIndex
      : selectedDegreeChordSelectorStateIndex;

  const copiedChordSelectorState =
    chordSearchType === "chord"
      ? copiedNashvilleChordSelectorState
      : copiedDegreeChordSelectorState;

  const setChordSelectorStateList: React.Dispatch<
    React.SetStateAction<ChordSelectorStateProps[]>
  > = (newValue) => {
    if (chordSearchType === "chord") {
      setNashvilleChordSelectorStateList(newValue);
    } else {
      setDegreeChordSelectorStateList(newValue);
    }
  };

  const setSelectedChordSelectorStateIndex: React.Dispatch<
    React.SetStateAction<undefined | number>
  > = (newValue) => {
    if (chordSearchType === "chord") {
      setSelectedNashvilleChordSelectorStateIndex(newValue);
    } else {
      setSelectedDegreeChordSelectorStateIndex(newValue);
    }
  };

  const setCopiedChordSelectorState: React.Dispatch<
    React.SetStateAction<ChordSelectorStateProps | undefined>
  > = (newValue) => {
    if (chordSearchType === "chord") {
      setCopiedNashvilleChordSelectorState(newValue);
    } else {
      setCopiedDegreeChordSelectorState(newValue);
    }
  };

  // -----------------------------------他

  useEffect(() => {
    setSiteHeaderInitialValues((prev) => ({
      ...prev,
      nashvilleChordSelectorStateList,
      degreeChordSelectorStateList,
    }));
  }, [
    nashvilleChordSelectorStateList,
    degreeChordSelectorStateList,
    setSiteHeaderInitialValues,
  ]);

  return {
    chordSelectorStateList,
    selectedChordSelectorStateIndex,
    copiedChordSelectorState,
    setChordSelectorStateList,
    setSelectedChordSelectorStateIndex,
    setCopiedChordSelectorState,
  };
}
