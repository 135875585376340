import { createQueryParamsString } from "~/libs/url/createQueryParamsString";
import { parseQueryParams } from "~/libs/url/parseQueryParams";

type QueryParams = {
  page?: number;
  degreeProgression?: string;
  q?: string;
};

export function getNamingListPagePath(queryParams: QueryParams): string {
  return `/namings${createQueryParamsString<QueryParams>(queryParams)}`;
}

export function parseNamingListPageQueryParam(
  queryString: string,
): Partial<QueryParams> {
  return parseQueryParams<QueryParams>(queryString, {
    page: "number",
    degreeProgression: "string",
    q: "string",
  });
}
