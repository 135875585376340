import type { ChordFunctionSelectorStateProps } from "./useProps";
import { ALTERNATIVE_CHORD_EXPRESSIONS } from "~/libs/chordProgressionUtil/ALTERNATIVE_CHORD_EXPRESSIONS";

export function convertChordFunctionSelectorStateToString({
  state,
  inputtingPlaceholder,
}: {
  state: ChordFunctionSelectorStateProps;
  inputtingPlaceholder: string;
}): string {
  // 空なら、プレースホルダーを返す
  if (
    state.selectedChordFunction === undefined &&
    !state.selectedAlternativeChordExpression
  ) {
    return inputtingPlaceholder;
  }

  // 代替コード表記がある場合はそれを返す
  if (state.selectedAlternativeChordExpression) {
    return ALTERNATIVE_CHORD_EXPRESSIONS[
      state.selectedAlternativeChordExpression
    ].value;
  }

  return `${state.selectedChordFunction}`;
}
