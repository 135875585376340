import type { Schema } from "./schema";
import { baseFetchFn } from "~/libs/http/baseFetchFn";

// NOTE: 自動生成しないが、逆にそれをちゃんとe2eテストする動機にしたい
export const postClient = async (
  args: Schema["pathParams"] & Schema["requestBody"],
): Promise<Schema["responseBody"]> => {
  return baseFetchFn({
    url: `/api/artists/${args.artistId}/albums`,
    method: "POST",
    body: {
      albumName: args.albumName,
    },
  }).then(async (res) => res.json());
};
