import type { Schema } from "./schema";
import { baseFetchFn } from "~/libs/http/baseFetchFn";

// NOTE: 自動生成しないが、逆にそれをちゃんとe2eテストする動機にしたい
export const getClient = async (
  args: Schema["pathParams"],
): Promise<Schema["responseBody"]> => {
  return baseFetchFn({
    url: `/api/artists/${args.artistId}/albums`,
    method: "GET",
  }).then(async (res) => res.json());
};
