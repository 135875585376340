/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useEffect, useRef, useState } from "react";
import { useKey } from "react-use";
import { useForm } from "@conform-to/react";
import { useNavigate } from "@remix-run/react";
import {
  type ChordSelectorStateProps,
  convertChordSelectorStateToString,
} from "../_ChordSelectors/ChordSelector/util";
import type { ChordSelectorProps } from "../_ChordSelectors/ChordSelector";
import type { SearchConditionDetailInputFormSchema } from "../SearchConditionInput/SearchConditionDetailInput";
import { useChordSelectorCommonState } from "../_ChordSelectors/ChordSelector/useProps";
import {
  type ChordFunctionSelectorStateProps,
  useChordFunctionSelectorCommonState,
} from "../_ChordSelectors/ChordFunctionSelector/useProps";
import type { ChordFunctionSelectorProps } from "../_ChordSelectors/ChordFunctionSelector";
import { convertChordFunctionSelectorStateToString } from "../_ChordSelectors/ChordFunctionSelector/util";
import { useAddPagesContentItems } from "../SiteMenuLinks/util";
import type {
  OtherPageLink,
  SearchTypeSelectorProps,
} from "./ChordProgressionSearchBar/SearchTypeSelector";
import { convertSearchHistoryToOtherPageLinks, getToneType } from "./util";
import { useChordProgressionSearchBarContext } from "./useContext";
import type { SiteHeaderProps } from ".";
import { t } from "~/libs/i18n/dictionaries";
import { DataId } from "~/libs/DataId";
import { toast } from "~/components/shadcnui/ui/use-toast";
import { _devLog } from "~/libs/log/_devLog";
import { exhaustiveGuard } from "~/libs/exhaustiveGuard";
import { searchHistoriesStorage } from "~/libs/data/localStorage/searchHistoriesStorage";
import { getTrackListPagePath } from "~/routes/tracks._index/path";
import type { SearchCondition } from "~/routes/tracks._index/util";
import { useLocaleContext } from "~/libs/i18n/context/LocaleProvider";

const chordSelectorStateListInitialValues: ChordSelectorStateProps = {
  selectedRootNote: undefined,
  selectedChordType: undefined,
  selectedExpansions: [],
  selectedSlashCode: undefined,
  selectedAlternativeChordExpression: undefined,
} as const;

const chordFunctionSelectorStateListInitialValues: ChordFunctionSelectorStateProps =
  {
    selectedChordFunction: undefined,
    selectedAlternativeChordExpression: undefined,
  } as const;

// TODO: エラーのあるコード、赤ボタンにしたほうがいいのでは？うーん

// TODO: コードの要素、左レーンから順に必須なやつを入力させたほうがいいのかも…（右側はdisabledみたいな）

// TODO: localeとか渡すと言語のjsonとかもクライアントに送っちゃう…？その言語のやつだけ送るならまあ分かるのでそうしたいな…
// TODO: 曲の属性絞り込みのコンボボックス、選択肢を動的に取得しないとネットワーク帯域がもったいないな

// TODO: free_input時、エンターで検索発火したいな

type Args = Pick<SiteHeaderProps, "isLogined">;

export function useSiteHeaderProps({ isLogined }: Args): SiteHeaderProps {
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // ステート
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const { siteHeaderInitialValues, setSiteHeaderInitialValues } =
    useChordProgressionSearchBarContext();

  // 検索条件詳細を開いているかどうか
  const [showSearchConditionDetail, setShowSearchConditionDetail] =
    useState(false);

  // スマホサイズにて、検索バーが開いているかどうか
  const [showSmartPhoneSearchBar, setShowSmartPhoneSearchBar] = useState(false);

  // free_inputの文字列
  const freeInputValueRef = useRef<string>("");

  // 検索タイプ
  const [searchType, setSearchType] = useState<
    SearchTypeSelectorProps["searchType"]
  >(siteHeaderInitialValues.searchType);

  // 検索条件詳細フォーム
  // TODO: 初期値をどこかから受け取る
  const [searchConditionDetailForm, searchConditionDetailFormFields] =
    useForm<SearchConditionDetailInputFormSchema>({
      defaultValue: {
        searchTargetSectionSpecifiedState: "all",
        searchTargetSections: ["A"],
        searchTargetSectionOverlapIncludedState: "overlapped",
        searchTargetPosition: "all",
        matchingTolerance: "fuzzy_match",
        trackAttributeSpecifiedState: "all",
        trackAttributeCountries: ["country1", "country2"],
        trackAttributeArtists: "artist1",
        trackAttributeAlbums: "album1",
        trackAttributeTags: ["tag1", "tag2"],
      } satisfies SearchConditionDetailInputFormSchema,
    });

  // コードセレクター周りの状態
  const {
    chordSelectorStateList,
    selectedChordSelectorStateIndex,
    copiedChordSelectorState,
    setChordSelectorStateList,
    setSelectedChordSelectorStateIndex,
    setCopiedChordSelectorState,
  } = useChordSelectorCommonState({
    chordSearchType: searchType,
    initialValues: {
      nashvilleChordSelectorStateList:
        siteHeaderInitialValues.nashvilleChordSelectorStateList,
      degreeChordSelectorStateList:
        siteHeaderInitialValues.degreeChordSelectorStateList,
    },
  });

  // コードファンクションセレクター周りの状態
  const {
    chordFunctionSelectorStateList,
    selectedChordFunctionSelectorStateIndex,
    copiedChordFunctionSelectorState,
    setChordFunctionSelectorStateList,
    setSelectedChordFunctionSelectorStateIndex,
    setCopiedChordFunctionSelectorState,
  } = useChordFunctionSelectorCommonState({
    initialValues: {
      chordFunctionSelectorStateList:
        siteHeaderInitialValues.chordFunctionSelectorStateList,
    },
  });

  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 他
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const navigate = useNavigate();

  const { locale: _locale } = useLocaleContext();
  const dict = t[_locale];

  const searchTypeOptionPropsList: SearchTypeSelectorProps["searchTypeOptionPropsList"] =
    [
      {
        label: dict.search_by_free_input_label,
        description: dict.search_by_free_input_description,
        isSelected: searchType === "free_input",
        onClick: () => {
          _devLog("onClickSearchTypeOption: search_by_free_input");
          setSearchType("free_input");
          setSiteHeaderInitialValues((current) => ({
            ...current,
            searchType: "free_input",
          }));
        },
      },
      {
        label: dict.search_by_chord_label,
        description: dict.search_by_chord_description,
        isSelected: searchType === "chord",
        onClick: () => {
          _devLog("onClickSearchTypeOption: search_by_chord");
          setSearchType("chord");
          setSiteHeaderInitialValues((current) => ({
            ...current,
            searchType: "chord",
          }));
        },
      },
      {
        label: dict.search_by_degree_label,
        description: dict.search_by_degree_description,
        isSelected: searchType === "degree",
        onClick: () => {
          _devLog("onClickSearchTypeOption: search_by_degree");
          setSearchType("degree");
          setSiteHeaderInitialValues((current) => ({
            ...current,
            searchType: "degree",
          }));
        },
      },
      {
        label: dict.search_by_function_label,
        description: dict.search_by_function_description,
        isSelected: searchType === "function",
        onClick: () => {
          _devLog("onClickSearchTypeOption: search_by_function");
          setSearchType("function");
          setSiteHeaderInitialValues((current) => ({
            ...current,
            searchType: "function",
          }));
        },
      },
    ];

  const addPagesContents = useAddPagesContentItems();

  const [otherPageLinks, setOtherPageLinks] = useState<OtherPageLink[]>([]);

  useEffect(() => {
    const links = searchHistoriesStorage
      .get()
      .map((history) => convertSearchHistoryToOtherPageLinks(history, _locale));

    setOtherPageLinks(links);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // ハンドラ
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

  // サイトヘッダーの初期値を設定
  useEffect(() => {
    setSiteHeaderInitialValues((prev) => ({
      ...prev,
      functionChordSelectorStateList: chordFunctionSelectorStateList,
    }));
  }, [chordFunctionSelectorStateList, setSiteHeaderInitialValues]);

  // ctrl(or cmd) + cで、選択しているコードをコピー
  useKey(
    (event) => {
      return (
        (event.metaKey && event.key === "c") ||
        (event.ctrlKey && event.key === "c")
      );
    },
    () => {
      switch (searchType) {
        case "free_input": {
          // フリーテキストの場合はデフォルトの挙動に任せる
          break;
        }
        case "chord":
        case "degree": {
          if (
            !showSearchConditionDetail ||
            selectedChordSelectorStateIndex === undefined
          ) {
            return;
          }

          const selectingChordSelectorState =
            chordSelectorStateList[selectedChordSelectorStateIndex];

          if (!selectingChordSelectorState) {
            return;
          }

          const text = convertChordSelectorStateToString({
            state: selectingChordSelectorState,
            inputtingPlaceholder: dict.inputting,
          });

          if (dict.inputting === text) {
            return;
          }

          setCopiedChordSelectorState(selectingChordSelectorState);

          toast({
            title: `${dict.copied_chord}: ${text}`,
            description: dict.copied_chord_description,
          });
          break;
        }
        case "function": {
          if (
            !showSearchConditionDetail ||
            selectedChordFunctionSelectorStateIndex === undefined
          ) {
            return;
          }

          const selectingChordFunctionSelectorState =
            chordFunctionSelectorStateList[
              selectedChordFunctionSelectorStateIndex
            ];

          if (!selectingChordFunctionSelectorState) {
            return;
          }

          const text =
            selectingChordFunctionSelectorState.selectedChordFunction;

          if (dict.inputting === text) {
            return;
          }

          setCopiedChordFunctionSelectorState(
            selectingChordFunctionSelectorState,
          );

          toast({
            title: `${dict.copied_chord}: ${text}`,
            description: dict.copied_chord_description,
          });
          break;
        }
        default: {
          exhaustiveGuard(searchType);
          break;
        }
      }
    },
  );

  // ctrl(or cmd) + vで、選択しているコードを貼り付け
  useKey(
    (event) => {
      return (
        (event.metaKey && event.key === "v") ||
        (event.ctrlKey && event.key === "v")
      );
    },
    () => {
      if (selectedChordSelectorStateIndex === undefined) {
        return;
      }

      switch (searchType) {
        case "free_input": {
          //TODO: テキストを貼り付ける。そのためにはfree_inputな時だけcontenteditableなdivにしないと、、
          break;
        }
        case "chord": {
          setChordSelectorStateList((current) => {
            if (!copiedChordSelectorState) {
              return current;
            }

            return current.map((state, index) =>
              index === selectedChordSelectorStateIndex
                ? copiedChordSelectorState
                : state,
            );
          });
          break;
        }
        case "degree": {
          setChordSelectorStateList((current) => {
            if (!copiedChordSelectorState) {
              return current;
            }

            return current.map((state, index) =>
              index === selectedChordSelectorStateIndex
                ? copiedChordSelectorState
                : state,
            );
          });
          break;
        }
        case "function": {
          setChordFunctionSelectorStateList((current) => {
            if (!copiedChordFunctionSelectorState) {
              return current;
            }

            return current.map((state, index) =>
              index === selectedChordFunctionSelectorStateIndex
                ? copiedChordFunctionSelectorState
                : state,
            );
          });
          break;
        }
        default: {
          exhaustiveGuard(searchType);
          break;
        }
      }
    },
  );

  const handleClickSearchTypeSelector = () => {
    _devLog("handleClickSearchTypeSelector");
    setShowSearchConditionDetail(false);
  };

  const handleFireSubmitSearchAction = () => {
    _devLog(
      "handleFireSubmitSearchAction",
      searchConditionDetailForm.value,
      freeInputValueRef.current,
    );
    let searchCondition: SearchCondition | undefined;
    switch (searchType) {
      case "free_input": {
        if (!freeInputValueRef.current) {
          // eslint-disable-next-line no-alert
          alert(dict.please_enter_search_criteria);
          return;
        }
        searchCondition = {
          searchType,
          q: freeInputValueRef.current,
        };
        break;
      }
      case "chord":
      case "degree": {
        if (chordSelectorStateList.length === 0) {
          // eslint-disable-next-line no-alert
          alert(dict.please_select_the_components_of_the_code);
          return;
        }
        searchCondition = {
          searchType,
          chordSelectorStatePropsList: chordSelectorStateList,
          searchConditionDetailInputFormValue:
            searchConditionDetailForm.value as SearchConditionDetailInputFormSchema,
        };
        break;
      }
      case "function": {
        if (chordFunctionSelectorStateList.length === 0) {
          // eslint-disable-next-line no-alert
          alert(dict.please_select_the_function_of_the_code);
          return;
        }
        searchCondition = {
          searchType,
          chordFunctionSelectorStatePropsList: chordFunctionSelectorStateList,
          searchConditionDetailInputFormValue:
            searchConditionDetailForm.value as SearchConditionDetailInputFormSchema,
        };
        break;
      }
      default: {
        exhaustiveGuard(searchType);
        break;
      }
    }

    if (searchCondition) {
      searchHistoriesStorage.add(searchCondition);
      navigate(
        getTrackListPagePath({
          searchCondition,
        }),
      );
    } else {
      // TODO: こっち来ること無いならエラー通知などする
    }
  };

  const handleActivateSearchBar = () => {
    _devLog("handleActivateSearchBar");
    // 開いてるなら、閉じる
    if (showSearchConditionDetail) {
      setShowSearchConditionDetail(false);
      return;
    }

    // free_inputの場合は、検索条件詳細を開かない
    if (searchType === "free_input") {
      return;
    }

    // 開く
    setShowSearchConditionDetail(true);
    setShowSmartPhoneSearchBar(true);
    if (chordSelectorStateList.length === 0) {
      setChordSelectorStateList([chordSelectorStateListInitialValues]);
      setSelectedChordSelectorStateIndex(0);
    } else {
      setSelectedChordSelectorStateIndex(chordSelectorStateList.length - 1);
    }
  };

  const handleClickOutsideSearchConditionDetail = (e: Event) => {
    _devLog("handleClickOutsideSearchConditionDetail", e);

    if (
      (e.target as HTMLElement).closest(
        `[data-id="${DataId.ChordProgressionSearchBar_SearchBar}"]`,
      )
    ) {
      return;
    }

    if (
      (e.target as HTMLElement).closest(
        `[data-id="${DataId.SearchConditionDetailInput_MultiSelectContent}"]`,
      )
    ) {
      return;
    }

    // FIXME: なぜかuseOnClickOutsideが全部に発火しちゃうので、手動で「これは問題ないよ」判定している。以下のif文は本来不要なはず…
    if (
      (e.target as HTMLElement).closest(
        `[data-id="${DataId.SearchConditionInput_Container}"]`,
      )
    ) {
      return;
    }

    setShowSearchConditionDetail(false);
    setShowSmartPhoneSearchBar(false);
  };

  const handleClickClearInputButton = () => {
    _devLog("handleClickClearInputButton");
    const newChordSelectorStateList =
      selectedChordSelectorStateIndex === undefined
        ? chordSelectorStateList
        : chordSelectorStateList.map((chordSelectorState, index) => {
            return index === selectedChordSelectorStateIndex
              ? chordSelectorStateListInitialValues
              : chordSelectorState;
          });
    setChordSelectorStateList(newChordSelectorStateList);
    setSiteHeaderInitialValues((current) => ({
      ...current,

      nashvilleChordSelectorStateList: newChordSelectorStateList,
    }));
  };

  const handleClickDeleteButton = () => {
    _devLog("handleClickDeleteButton");
    setChordSelectorStateList((current) => {
      if (selectedChordSelectorStateIndex === undefined) {
        return current;
      }

      return current.filter(
        (_, index) => index !== selectedChordSelectorStateIndex,
      );
    });
    setSelectedChordSelectorStateIndex(
      selectedChordSelectorStateIndex === 0 ||
        selectedChordSelectorStateIndex === undefined
        ? undefined
        : selectedChordSelectorStateIndex - 1,
    );
  };

  const handleClickSelectorButton = (propsIndex: number) => () => {
    _devLog("handleClickSelectorButton", propsIndex);
    if (searchType === "chord" || searchType === "degree") {
      setSelectedChordSelectorStateIndex(propsIndex);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (searchType === "function") {
      setSelectedChordFunctionSelectorStateIndex(propsIndex);
    }
    setShowSearchConditionDetail(true);
  };

  const handleClickRootNote: ChordSelectorProps["onClickRootNote"] = (
    rootNote,
  ) => {
    _devLog("handleClickRootNote", rootNote);
    if (selectedChordSelectorStateIndex === undefined) {
      setChordSelectorStateList([
        {
          ...chordSelectorStateListInitialValues,
          selectedRootNote: rootNote,
        },
      ]);
      setSelectedChordSelectorStateIndex(0);
      return;
    }

    setChordSelectorStateList((current) => {
      return current.map((state, index) =>
        index === selectedChordSelectorStateIndex
          ? {
              ...state,
              selectedRootNote:
                rootNote === state.selectedRootNote ? undefined : rootNote,
              selectedAlternativeChordExpression: undefined,
            }
          : state,
      );
    });
  };

  const handleClickChordType: ChordSelectorProps["onClickChordType"] = (
    chordType,
  ) => {
    _devLog("handleClickChordType", chordType);
    if (selectedChordSelectorStateIndex === undefined) {
      setChordSelectorStateList([
        {
          ...chordSelectorStateListInitialValues,
          selectedChordType: chordType,
        },
      ]);
      setSelectedChordSelectorStateIndex(0);
      return;
    }

    setChordSelectorStateList((current) => {
      return current.map((state, index) =>
        index === selectedChordSelectorStateIndex
          ? {
              ...state,
              selectedChordType:
                chordType === state.selectedChordType ? undefined : chordType,
              selectedAlternativeChordExpression: undefined,
            }
          : state,
      );
    });
  };

  const handleClickExpansion: ChordSelectorProps["onClickExpansion"] = (
    expansion,
  ) => {
    _devLog("handleClickExpansion", expansion);
    if (selectedChordSelectorStateIndex === undefined) {
      setChordSelectorStateList([
        {
          ...chordSelectorStateListInitialValues,
          selectedExpansions: [expansion],
        },
      ]);
      setSelectedChordSelectorStateIndex(0);
      return;
    }

    setChordSelectorStateList((current) => {
      return current.map((state, index) =>
        index === selectedChordSelectorStateIndex
          ? {
              ...state,
              selectedExpansions: state.selectedExpansions.includes(expansion)
                ? state.selectedExpansions.filter((e) => e !== expansion)
                : [...state.selectedExpansions, expansion],
              selectedAlternativeChordExpression: undefined,
            }
          : state,
      );
    });
  };

  const handleClickSlashCode: ChordSelectorProps["onClickSlashCode"] = (
    slashCode,
  ) => {
    _devLog("handleClickSlashCode", slashCode);
    if (selectedChordSelectorStateIndex === undefined) {
      setChordSelectorStateList([
        {
          ...chordSelectorStateListInitialValues,
          selectedSlashCode: slashCode,
        },
      ]);
      setSelectedChordSelectorStateIndex(0);
      return;
    }

    setChordSelectorStateList((current) => {
      return current.map((state, index) =>
        index === selectedChordSelectorStateIndex
          ? {
              ...state,
              selectedSlashCode:
                slashCode === state.selectedSlashCode ? undefined : slashCode,
              selectedAlternativeChordExpression: undefined,
            }
          : state,
      );
    });
  };

  const handleClickAlternativeChordExpression: ChordSelectorProps["onClickAlternativeChordExpression"] =
    (alternativeChordExpression) => {
      _devLog(
        "handleClickAlternativeChordExpression",
        alternativeChordExpression,
      );
      if (selectedChordSelectorStateIndex === undefined) {
        setChordSelectorStateList([
          {
            ...chordSelectorStateListInitialValues,
            selectedAlternativeChordExpression: alternativeChordExpression,
          },
        ]);
        setSelectedChordSelectorStateIndex(0);
        return;
      }

      setChordSelectorStateList((current) => {
        return current.map((state, index) =>
          index === selectedChordSelectorStateIndex
            ? {
                ...chordSelectorStateListInitialValues,
                selectedAlternativeChordExpression: alternativeChordExpression,
              }
            : state,
        );
      });
    };

  const handleClickAddIcon = () => {
    _devLog("handleClickAddIcon");

    setShowSearchConditionDetail(true);
    if (searchType === "chord" || searchType === "degree") {
      setChordSelectorStateList((current) => [
        ...current,
        chordSelectorStateListInitialValues,
      ]);
      setSelectedChordSelectorStateIndex(chordSelectorStateList.length);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (searchType === "function") {
      setChordFunctionSelectorStateList((current) => [
        ...current,
        chordFunctionSelectorStateListInitialValues,
      ]);
      setSelectedChordFunctionSelectorStateIndex(
        chordFunctionSelectorStateList.length,
      );
    }
  };

  const handleClickChordFunction: ChordFunctionSelectorProps["onClickChordFunction"] =
    (chordFunction) => {
      _devLog("handleClickChordFunction", chordFunction);

      // コードファンクションセレクターの要素が無いなら、作成
      if (selectedChordFunctionSelectorStateIndex === undefined) {
        setChordFunctionSelectorStateList([
          {
            selectedChordFunction: chordFunction,
            selectedAlternativeChordExpression: undefined,
          },
        ]);
        setSelectedChordFunctionSelectorStateIndex(0);
        return;
      }

      setChordFunctionSelectorStateList((current) => {
        return current.map((state, index) =>
          index === selectedChordFunctionSelectorStateIndex
            ? {
                selectedChordFunction:
                  chordFunction === state.selectedChordFunction
                    ? undefined
                    : chordFunction,
                selectedAlternativeChordExpression: undefined,
              }
            : state,
        );
      });
    };

  const handleClickAlternativeChordFunctionExpression: ChordFunctionSelectorProps["onClickAlternativeChordExpression"] =
    (alternativeChordFunctionExpression) => {
      _devLog(
        "handleClickAlternativeChordFunctionExpression",
        alternativeChordFunctionExpression,
      );
      if (selectedChordFunctionSelectorStateIndex === undefined) {
        setChordFunctionSelectorStateList([
          {
            selectedChordFunction: undefined,
            selectedAlternativeChordExpression:
              alternativeChordFunctionExpression,
          },
        ]);
        setSelectedChordFunctionSelectorStateIndex(0);
        return;
      }

      setChordFunctionSelectorStateList((current) => {
        return current.map((state, index) =>
          index === selectedChordFunctionSelectorStateIndex
            ? {
                selectedChordFunction: undefined,
                selectedAlternativeChordExpression:
                  alternativeChordFunctionExpression,
              }
            : state,
        );
      });
    };

  const handleClickClearFunctionInputButton = () => {
    _devLog("handleClickClearFunctionInputButton");
    setChordFunctionSelectorStateList((current) => {
      if (selectedChordFunctionSelectorStateIndex === undefined) {
        return current;
      }

      return current.map((state, index) =>
        index === selectedChordFunctionSelectorStateIndex
          ? chordFunctionSelectorStateListInitialValues
          : state,
      );
    });
  };

  const handleClickDeleteFunctionButton = () => {
    _devLog("handleClickDeleteFunctionButton");
    setChordFunctionSelectorStateList((current) => {
      if (selectedChordFunctionSelectorStateIndex === undefined) {
        return current;
      }

      return current.filter(
        (_, index) => index !== selectedChordFunctionSelectorStateIndex,
      );
    });
    setSelectedChordFunctionSelectorStateIndex(
      selectedChordFunctionSelectorStateIndex === 0 ||
        selectedChordFunctionSelectorStateIndex === undefined
        ? undefined
        : selectedChordFunctionSelectorStateIndex - 1,
    );
  };

  const handleClickSmartPhoneOpenSearchButton = () => {
    _devLog("handleClickSmartPhoneOpenSearchButton");

    // free_inputの場合は、検索条件詳細を開かない
    if (searchType === "free_input") {
      setShowSmartPhoneSearchBar(true);
      // TODO: 開いた時に同時にフォーカスを与えたい
      return;
    }

    // 開く
    setShowSearchConditionDetail(true);
    setShowSmartPhoneSearchBar(true);

    // 一つもないなら、一つ追加
    if (chordSelectorStateList.length === 0) {
      setChordSelectorStateList([chordSelectorStateListInitialValues]);
      setSelectedChordSelectorStateIndex(0);
    } else {
      setSelectedChordSelectorStateIndex(chordSelectorStateList.length - 1);
    }
  };

  const handleEscKeyDownWhenChordProgressionSearchBarOpen = () => {
    _devLog("handleEscKeyDownWhenChordProgressionSearchBarOpen");
    setShowSearchConditionDetail(false);
    setShowSmartPhoneSearchBar(false);
  };

  const handleChangeFreeInput = (value: string) => {
    _devLog("handleChangeFreeInput", value);
    freeInputValueRef.current = value;
  };

  // TODO: ここらへんのリソース読み込みはいい感じにキャッシュを使う
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleOpenSectionsMultiSelect = () => {
    _devLog("handleOpenSectionsMultiSelect");

    // TODO
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleOpenCountriesMultiSelect = () => {
    _devLog("handleOpenCountriesMultiSelect");

    // TODO
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleOpenTagsMultiSelect = () => {
    _devLog("handleOpenTagsMultiSelect");

    // TODO
  };

  return {
    iconHref: "/",
    showSmartPhoneSearchBar,
    isLogined,
    chordProgressionSearchBarProps: {
      chordSelectorButtonsProps: {
        noHighlight: !showSearchConditionDetail,
        selectorButtonPropsLists: ["chord", "degree"].includes(searchType)
          ? chordSelectorStateList.map((state, propsIndex) => [
              {
                placeholder: convertChordSelectorStateToString({
                  state,
                  inputtingPlaceholder: dict.inputting,
                }),
                isSelected: propsIndex === selectedChordSelectorStateIndex,
                onClick: handleClickSelectorButton(propsIndex),
              },
            ])
          : chordFunctionSelectorStateList.map((state, propsIndex) => [
              {
                placeholder: convertChordFunctionSelectorStateToString({
                  state,
                  inputtingPlaceholder: dict.inputting,
                }),
                isSelected:
                  propsIndex === selectedChordFunctionSelectorStateIndex,
                onClick: handleClickSelectorButton(propsIndex),
              },
            ]),
      },
      searchTypeSelectorProps: {
        searchType,
        searchTypeOptionPropsList,
        otherPageLinks,
        // TODO: onClickOtherPageLinksを作って、クリックしたら検索バーに反映しないと。
        // TODO: いや、それは駄目だ。検索結果画面で、クエリパラメータを見て反映したほうがいい。でないとブックマークバーから来た時に反映されない。
        onClickSearchTypeSelector: handleClickSearchTypeSelector,
      },
      searchConditionInputProps: showSearchConditionDetail
        ? {
            onClickOutside: handleClickOutsideSearchConditionDetail,
            searchConditionDetailInputProps: {
              formState: [
                searchConditionDetailForm,
                searchConditionDetailFormFields,
              ],
              searchTargetSection: {
                options: {
                  // TODO: サーバーから取得
                  sections: ["1"],
                },
                onOpen: {
                  sectionsMultiSelect: handleOpenSectionsMultiSelect,
                },
              },
              trackAttribute: {
                options: {
                  // TODO: サーバーから取得
                  countries: ["1"],
                  tags: ["1"],
                },
                onOpen: {
                  countriesMultiSelect: handleOpenCountriesMultiSelect,
                  tagsMultiSelect: handleOpenTagsMultiSelect,
                },
              },
            },
            chordSearchType: searchType,
            chordSelectorProps: {
              toneType: getToneType(searchType) ?? "nashville",
              selectedRootNote:
                selectedChordSelectorStateIndex === undefined
                  ? undefined
                  : chordSelectorStateList[selectedChordSelectorStateIndex]
                      ?.selectedRootNote,
              selectedChordType:
                selectedChordSelectorStateIndex === undefined
                  ? undefined
                  : chordSelectorStateList[selectedChordSelectorStateIndex]
                      ?.selectedChordType,
              selectedExpansions:
                selectedChordSelectorStateIndex === undefined
                  ? []
                  : chordSelectorStateList[selectedChordSelectorStateIndex]
                      ?.selectedExpansions ?? [],
              selectedSlashCode:
                selectedChordSelectorStateIndex === undefined
                  ? undefined
                  : chordSelectorStateList[selectedChordSelectorStateIndex]
                      ?.selectedSlashCode,
              selectedAlternativeChordExpression:
                selectedChordSelectorStateIndex === undefined
                  ? undefined
                  : chordSelectorStateList[selectedChordSelectorStateIndex]
                      ?.selectedAlternativeChordExpression,
              onClickRootNote: handleClickRootNote,
              onClickChordType: handleClickChordType,
              onClickExpansion: handleClickExpansion,
              onClickSlashCode: handleClickSlashCode,
              onClickAlternativeChordExpression:
                handleClickAlternativeChordExpression,
              onClickClearInputButton: handleClickClearInputButton,
              onClickDeleteButton: handleClickDeleteButton,
            },
            chordFunctionSelectorProps: {
              selectedChordFunction:
                selectedChordFunctionSelectorStateIndex === undefined
                  ? undefined
                  : chordFunctionSelectorStateList[
                      selectedChordFunctionSelectorStateIndex
                    ]?.selectedChordFunction,
              selectedAlternativeChordExpression:
                selectedChordFunctionSelectorStateIndex === undefined
                  ? undefined
                  : chordFunctionSelectorStateList[
                      selectedChordFunctionSelectorStateIndex
                    ]?.selectedAlternativeChordExpression,
              onClickChordFunction: handleClickChordFunction,
              onClickAlternativeChordExpression:
                handleClickAlternativeChordFunctionExpression,
              onClickClearInputButton: handleClickClearFunctionInputButton,
              onClickDeleteButton: handleClickDeleteFunctionButton,
            },
          }
        : undefined,
      onFireSubmitAction: handleFireSubmitSearchAction,
      onActivateSearchBar: handleActivateSearchBar,
      onClickAddIcon: handleClickAddIcon,
      onEscapeKeyDown: handleEscKeyDownWhenChordProgressionSearchBarOpen,
      onChangeFreeInput: handleChangeFreeInput,
    },
    dropDownMenuContentProps: {
      contents: addPagesContents,
    },
    onClickSmartPhoneOpenSearchButton: handleClickSmartPhoneOpenSearchButton,
  };
}
