// TODO: 認可のあれこれによってはjwtを乗せたリフレッシュトークン対応などする必要あるかも
export async function baseFetchFn({
  url,
  method,
  body,
  queryParams,
  isMultipartFormData,
}: {
  url: string;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  body?: Record<PropertyKey, unknown>;
  queryParams?: Record<PropertyKey, string>;
  isMultipartFormData?: boolean;
}): Promise<Response> {
  return fetch(url, {
    method,
    headers: isMultipartFormData ? {} : { "Content-Type": "application/json" },
    ...(queryParams && { queryParams }),
    ...(body && { body: JSON.stringify(body) }),
  });
}
