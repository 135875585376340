import { SHORT_APP_NAME } from "~/libs/APP_NAME";

const localStorageKeyPrefix = `${SHORT_APP_NAME}_`;

export function getItemFromLocalStorage(key: string): string | null {
  // eslint-disable-next-line no-restricted-syntax
  return localStorage.getItem(`${localStorageKeyPrefix}${key}`);
}

export function setItemToLocalStorage(key: string, value: string): void {
  // eslint-disable-next-line no-restricted-syntax
  localStorage.setItem(`${localStorageKeyPrefix}${key}`, value);
}
