import { useState } from "react";
import { Icons } from "../_icons";
import type { SiteMenuLinksProps } from ".";
import { getHomePagePath } from "~/routes/_index/path";
import { getNamingListPagePath } from "~/routes/namings._index/path";
import { getListPagePath } from "~/routes/lists._index/path";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";
import { getMyPagePath } from "~/routes/my_page._index/path";
import { changeTheme, defaultThemes } from "~/libs/theme";
import { apiClient } from "~/routes/_apiClient";

type SiteViewMenu = "home" | "naming" | "list" | "my_page";

export function useSiteMenuLinksProps({
  selectedMenu,
  noFAB,
  isLogined,
  initialSelectedThemeId,
}: {
  selectedMenu: SiteViewMenu | undefined;
  noFAB?: boolean;
  isLogined: boolean;
  initialSelectedThemeId: string;
}): SiteMenuLinksProps {
  const dict = useDictionary();

  const [currentThemeId, setCurrentThemeId] = useState(initialSelectedThemeId);

  // TODO: default以外にもいずれ選択できるようにする
  const themes = Object.values(defaultThemes).map((theme) => ({
    id: theme.id,
    label: theme.label,
    isSelected: theme.id === currentThemeId,
  }));

  const handleSelectTheme = (themeId: string) => {
    const themeIdBeforeRequest = currentThemeId;
    changeTheme(themeId);
    setCurrentThemeId(themeId);
    apiClient.me.theme
      .post({ themeId })
      .then(() => {
        changeTheme(themeId);
        setCurrentThemeId(themeId);
      })
      .catch(() => {
        changeTheme(themeIdBeforeRequest);
        setCurrentThemeId(themeIdBeforeRequest);
        // TODO: トーストとかでエラーを知らせる
      });
  };

  return {
    // TODO: あとその右に「▼テーマ」みたいなのも用意してそっちで色変えられるようにするとかねもはや
    // TODO: そもそもhomepageのクエリパラメータや処理も修正するはず
    links: [
      {
        href: getHomePagePath({}),
        icon: <Icons.Domain.Home size={16} />,
        label: "HOME",
        isSelected: selectedMenu === "home",
      },
      {
        icon: <Icons.Domain.Naming size={16} />,
        href: getNamingListPagePath({}),
        label: dict.naming,
        isSelected: selectedMenu === "naming",
      },
      {
        icon: <Icons.Domain.List size={16} />,
        href: getListPagePath({}),
        label: dict.list,
        isSelected: selectedMenu === "list",
      },
      {
        icon: <Icons.Util.User size={16} />,
        href: getMyPagePath(),
        label: dict.my_page,
        isSelected: selectedMenu === "my_page",
      },
      // TODO: 掲示板もほしい気がする。やっぱりユーザーが来るモチベーションになると思うので
    ],
    themes,
    noFAB,
    isLogined,
    onSelectTheme: handleSelectTheme,
  };
}
