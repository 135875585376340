import type { Schema } from "./schema";
import { baseFetchFn } from "~/libs/http/baseFetchFn";

// NOTE: 自動生成しないが、逆にそれをちゃんとe2eテストする動機にしたい
export const postClient = async (
  args: Schema["pathParams"] & Schema["requestBody"],
): Promise<Schema["responseBody"]> => {
  return baseFetchFn({
    url: `/api/contact`,
    method: "POST",
    body: {
      "email": args.email,
      "name": args.name,
      "content": args.content,
      "cf-turnstile-response": args["cf-turnstile-response"],
    },
  }).then(async (res) => res.json());
};
