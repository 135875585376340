import type { SearchCondition } from "./util";
import { parseQueryParams } from "~/libs/url/parseQueryParams";
import { createQueryParamsString } from "~/libs/url/createQueryParamsString";

// TODO: ページナンバーは？

type TrackListPageQueryParams = {
  searchCondition: SearchCondition | undefined;
};

export function getTrackListPagePath({
  searchCondition,
}: TrackListPageQueryParams): string {
  return `/tracks${createQueryParamsString<TrackListPageQueryParams>({
    searchCondition,
  })}`;
}

export function parseTrackListPageQueryParam(
  queryString: string,
): Partial<TrackListPageQueryParams> {
  return parseQueryParams<TrackListPageQueryParams>(queryString, {
    searchCondition: "object",
  });
}
