/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
type ParseOptions<T> = Record<
  keyof T,
  "string" | "number" | "boolean" | "object"
>;

// eslint-disable-next-line max-statements
function parseValue(value: string, type: string) {
  try {
    switch (type) {
      case "string": {
        // 両端のダブルクォートを削除
        if (value.startsWith('"') && value.endsWith('"')) {
          return value.slice(1, -1);
        }

        return value;
      }
      case "number": {
        const num = Number.parseFloat(value);
        if (Number.isNaN(num)) {
          throw new TypeError(`Cannot parse "${value}" as number`);
        }
        return num;
      }
      case "boolean": {
        if (value === "true") return true;
        if (value === "false") return false;
        throw new Error(`Cannot parse "${value}" as boolean`);
      }
      case "object": {
        const parsedValue = JSON.parse(value);
        if (typeof parsedValue !== "object") {
          throw new TypeError(`Cannot parse "${value}" as object`);
        }

        return parsedValue;
      }
      default: {
        throw new Error(`Unknown type "${type}"`);
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return undefined;
  }
}

export function parseQueryParams<T>(
  queryString: string,
  options: ParseOptions<T>,
): Partial<T> {
  const params = new URLSearchParams(queryString);
  const result: Partial<T> = {};

  // eslint-disable-next-line unicorn/no-array-for-each
  params.forEach((value, key) => {
    // @ts-expect-error 無視する
    const parsedValue = parseValue(value, options[key]);
    // @ts-expect-error 無視する
    result[key] = parsedValue;
  });

  return result;
}
