import { useState } from "react";
import type { ChordFunctionSelectorProps } from ".";
import type { ChordFunction } from "~/libs/chordProgressionUtil/ChordFunction";

export type ChordFunctionSelectorStateProps = {
  selectedChordFunction: ChordFunction | undefined;
  selectedAlternativeChordExpression: ChordFunctionSelectorProps["selectedAlternativeChordExpression"];
};

/**
 * ChordSearchTypeがfunctionのやつのための状態をまとめたhooks
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useChordFunctionSelectorCommonState({
  initialValues,
}: {
  initialValues: {
    chordFunctionSelectorStateList: ChordFunctionSelectorStateProps[];
  };
}) {
  // コードファンクションセレクターの状態リスト
  const [chordFunctionSelectorStateList, setChordFunctionSelectorStateList] =
    useState<ChordFunctionSelectorStateProps[]>(
      initialValues.chordFunctionSelectorStateList,
    );

  // 選択しているコードファンクションセレクターのインデックス
  const [
    selectedChordFunctionSelectorStateIndex,
    setSelectedChordFunctionSelectorStateIndex,
  ] = useState<undefined | number>();

  // コピーしたコードファンクションセレクターの状態
  const [
    copiedChordFunctionSelectorState,
    setCopiedChordFunctionSelectorState,
  ] = useState<ChordFunctionSelectorStateProps | undefined>();

  return {
    chordFunctionSelectorStateList,
    selectedChordFunctionSelectorStateIndex,
    copiedChordFunctionSelectorState,
    setChordFunctionSelectorStateList,
    setSelectedChordFunctionSelectorStateIndex,
    setCopiedChordFunctionSelectorState,
  };
}
