import { getItemFromLocalStorage, setItemToLocalStorage } from "./_util";
import type { SearchCondition } from "~/routes/track._index/util";

const key = "searchHistory";

export type SearchHistory = SearchCondition;

function get(): SearchHistory[] {
  const value = getItemFromLocalStorage(key);
  if (value) {
    return JSON.parse(value) as SearchHistory[];
  }

  return [];
}

/**
 * 5個まで保存する
 * NOTE: 5個を超えた場合は古いものから削除する
 */
function add(searchHistories: SearchHistory): void {
  const MAX_SEARCH_HISTORIES = 5;
  const searchHistoriesFromStorage = get();

  const newSearchHistories = [
    searchHistories,
    ...searchHistoriesFromStorage,
  ].slice(0, MAX_SEARCH_HISTORIES);

  setItemToLocalStorage(key, JSON.stringify(newSearchHistories));
}

export const searchHistoriesStorage = {
  get,
  add,
};
