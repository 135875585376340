import type { Schema } from "./schema";
import { baseFetchFn } from "~/libs/http/baseFetchFn";

// NOTE: 自動生成しないが、逆にそれをちゃんとe2eテストする動機にしたい
export const postClient = async (
  args: Schema["pathParams"] & Schema["requestBody"],
): Promise<Schema["responseBody"]> => {
  return baseFetchFn({
    url: `/api/me/theme`,
    method: "POST",
    body: {
      themeId: args.themeId,
    },
  }).then(async (res) => res.json());
};
